.loginBody {
  height: 100vh;
  background: linear-gradient(128.09deg, #399700 1.21%, #a3cc00 142.09%);
}
.loginCard {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.whiteBox{
    position: relative;
    background-color: white;
    border-radius: 10px;
    padding: 40px 50px;
    margin-top: 50px;
}

.fadedBox{
  width:100%; 
  height:100%; 
  z-index:1;
  background-color:rgba(255, 255, 255,0.6);
  position:absolute;
  top:0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #333333;
}

.enterCred {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #333333;
  margin-top: 10px;
}

.forgotPass {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #000000;
}

.rememberMe {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.invalidUser{
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #F60000;
}


.demo {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #333333;

}


@media (min-width: 0) and (max-width: 900px) {
    .whiteBox{
        padding: 40px 25px;
    }
  }
  
