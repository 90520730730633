
.notHeader {
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.notHeaderHigh {
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.notifyDropDown{
    width:20px;
    height:20px;

}

.notifyDropDown:hover{
    cursor: pointer;
}

.notSubHeader {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 8px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
  margin-top: 5px;
}

