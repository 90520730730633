.whiteBox {
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 40px 50px;
  margin-top: 50px;
}

.fadedBox {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #333333;
}

.enterCred {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #333333;
  margin-top: 10px;
}

.invalidUser {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #f60000;
}

@media (min-width: 0) and (max-width: 900px) {
  .whiteBox {
    padding: 40px 25px;
  }
}

.inputOtp {
  border: 1px solid black;
  border-radius: 10%;
  margin-top: 2px;
  width: 80% !important;
  font-size: xx-large;
  background-color: aliceblue;
}

.errorStyle {
  border: 1px solid #c73e1d;
  border-radius: 10%;
  margin-top: 2px;
  width: 80% !important;
  margin: 3px;
  font-size: xx-large;
  background-color: aliceblue;
}
