.noPriceChanges{
  text-align: center;
  margin:20px 0px;
  padding:50px 0px;
  background-color: #F9FBFA;
  border-radius: 5px;

}

.cardItemP {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  height: 36px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
}

.cardItemSub {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
  color: #fd8a02;
  opacity: 0.5;
}

.yourPrice {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 12px;
  color: #000000;
}

.amout {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.rankSpan {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #e67d02;
}

.lowest {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 12px;

  color: #55df00;
}

.lowestPrice {
  margin-top: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;

  color: #55df00;
}

.place {
  margin-top: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #000000;

  opacity: 0.5;
}

.highest {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 12px;
  color: #f70000;
}

.highestPrice {
  margin-top: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: #f70000;
}

.difference {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #000000;
}

.recentChange {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #000000;
}

.product {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.productHead {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 9px;

  color: #000000;

  opacity: 0.25;
}

.viewAllPrice {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.manageDiscount {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #333333;
  border: 1px solid #333333;
  border-radius: 2px;
  width: 100%;
  padding: 13px 0;
}

.turnOnNotification {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.notifyDiv:hover {
  cursor: pointer;
}
