.modal {
    z-index: 3;
    position: fixed;
    top:20vh; 
    left: 15px; 
    right: 15px; 
    /* bottom: 0; */
    margin:auto;
    max-width: 600px; 
    height: auto;
    min-height: 400px;
    padding: 20px;
  }

  .cardItemP {
    position: relative;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* height: 36px; */
    line-height: 18px;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    text-transform: capitalize;
    color: #000000;
  }

.cardItemSub {
    position: relative;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 12px;
    color: #fd8a02;
    opacity: 0.5;
    top: 5px;

  }

  .product {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-transform: capitalize;
  }
  