.notification {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  padding-left:10px;
}

.notifyNotSelect {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #000000;
  background-color: #ffffff;
  padding: 8px;
}

.notifySelect {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #000000;
  padding: 8px;
  border-radius: 2px;
}

.fadedBox {
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SidebarBox {
  width: 100%;
}



.allSelected {
}

.unreadSelected {
}

/* .notification {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
}

.notSubHeader {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 8px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
}

.notHeader {
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.notHeaderHigh {
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.notifyNotSelect {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #000000;
  background-color: #ffffff;
  padding: 8px;
}

.notifySelect {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #000000;
  padding: 8px;
  border-radius: 2px;
}

.allSelected {
}

.unreadSelected {
} */