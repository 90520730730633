.uploadInput{
    background-color: blue;
    width: 80%;
    height: 400px;
    border-radius: 5px;

}

.bluredText{
    color: rgb(138, 138, 138);
}

.errorText{
    font-size: small;
    color: #d32f2f;
}

.boxColor{
    background-color: '#E0EBE5';
    opacity: '75%';
}