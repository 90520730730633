.tableTopic {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}

.tableheader {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  color: #333333;

  opacity: 0.45;
}

.pDifferenceZero {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
  text-align: right;
  color: grey;
  margin-top: 3px;
}

.pCompPriceDifference {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  margin-top: 3px;
  color: #000000;
}
.pCompPriceDifferenceGreen{
  color: #399700;
}

.pCompPriceDifferenceRed{
  color: #F60000;
}


.pDifference {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-top: 3px;
  color: #000000;
}



.cPrice {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.product {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.shop {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;

  color: #1a1a1a;
}

.yourPrice {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.fadedBox{
  width:100%; 
  height:100%; 
  min-height: 100vh;
  z-index:1;
  background-color:rgba(255, 255, 255,0.6);
  position:absolute;
  top:0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent{
  position:relative;
}

.shopName{
  background-color: #399700;
  align-self: center;
  align-items: center;
  align-content: center;
}

/* .actionBtn:hover{
  cursor: pointer;
} */
