.optionPicture{
    width: 50px;
    height: 50px;
}

.caption{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}