.logoImg {
  margin-left: 260px;
}

.searchText {
  display: block;
}

@media (max-width: 600px) {
  .logoImg {
    margin-left: 0;
  }
  .searchText {
    display: none;
  }
}
