.mainDiv {
  margin-left: 44px;
  margin-right: 44px;
}

.tableTopic {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}

.tableheader {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  opacity: 0.45;
}

.areaChartHeader {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  text-align: center;
  padding-top: 10px;
}


@media (min-width: 0) and (max-width: 900px) {
  .mainDiv {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (max-width: 455px) {
  .mainDiv {
    margin-left: 14px;
    margin-right: 14px;
  }
}
