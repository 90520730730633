.outer__container {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(128.09deg, #399700 1.21%, #a3cc00 142.09%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rubik';
  padding-bottom: 50px;
}

.fadedBox {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner__container {
  position: relative;
  width: 670px;
  height: auto;
  background: #ffffff;

  border-radius: 10px;
  padding: 48px;
}

.select__competitor__one {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #333333;

  margin-top: 22px;
  margin-bottom: 10px;
}
.select__competitor__two {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #333333;
  margin-bottom: 40px;
}

.form__control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}
input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #399700;
  width: 1.3em;
  height: 1.3em;
  border: 0.2em solid #399700;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #399700;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type='checkbox']:checked::before {
  transform: scale(1);
}
.selectionBox {
  background-color: #e0ebe5;
  border-radius: 5px;
}
.form__control {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  /* identical to box height, or 88% */

  color: #333333;
  cursor: pointer;
  padding: 15px 20px 15px 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 850px) {
  .inner__container {
    width: 90%;
    padding: 25px;
  }
  .logo {
    margin-top: 5%;
  }
  .added__competitors {
    padding: 20px;
    background-color: #f9fbfa;
  }
}

@media only screen and (max-width: 400px) {
  .inner__container {
    width: 90%;
  }
  .logo {
    margin-top: 5%;
  }
  .inner__container {
    padding: 15px;
  }

  .added__competitors {
    padding: 20px;
    background-color: #f9fbfa;
  }
}

input[type='radio'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #399700;
  width: 1.3em;
  height: 1.3em;
  border: 0.2em solid #399700;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #399700;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type='radio']:checked::before {
  transform: scale(1);
}

.error__alert {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
