.heading{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;

    color: #333333;
}

.subHeading{
    margin-top: 1pt;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    text-align: center;

    color: #333333;
}

.formClass{
    width: 100%;
    margin: 0%;   
}