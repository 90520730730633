.dropBoxText{
    font-family: 'Rubik';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 14px;
/* identical to box height, or 140% */

text-transform: uppercase;

color: #333333;
}