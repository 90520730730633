.tableTopic {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}

.tableheader {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  color: #333333;

  opacity: 0.45;
}

.itemName {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  min-height: 36px;
  max-height: 54px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
}

.itemSubName {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
  color: #fd8a02;
  opacity: 0.5;
}

.cPrice {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.prodTableContent {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  vertical-align: baseline;
  text-transform: capitalize;
}

.prodTableContentOpacity {
  opacity: 0.9;
}

.pDifference {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}
.pDifferenceGreen{
  color: #399700;
}
.pDifferenceRed{
  color:#F60000;
}
.pStore {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.shop {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #1a1a1a;
}

.yourPrice {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
