.CompanyDetailsDiv {
  justify-content: center;
  background: linear-gradient(128.09deg, #399700 1.21%, #a3cc00 142.09%);
  min-height: 100vh;
  align-items: center;
}

html {
  background: linear-gradient(128.09deg, #399700 1.21%, #a3cc00 142.09%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
