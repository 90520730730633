.recentTopic {
  font-family: 'Rubik';
  margin-top: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;

  color: #000000;
}

.homeDiv {
  margin-left: 44px;
  margin-right: 44px;
  padding-bottom: 5px;
  position: relative;
}

@media (min-width: 0) and (max-width: 900px) {
  .homeDiv {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (max-width: 455px) {
  .homeDiv {
    margin-left: 14px;
    margin-right: 14px;
  }
}

.fadedBox{
  width:100%; 
  height:100%; 
  min-height: 100vh;
  z-index:1;
  background-color:rgba(255, 255, 255,0.6);
  position:absolute;
  top:0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}